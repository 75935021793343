import { useQuery } from 'react-query';

import { axios } from 'lib/axios';

export async function getScheduledPosts() {
  const { data } = await axios.get('/profile/schedule');
  return data;
}

export const useScheduledPosts = () => {
  return useQuery('scheduled_post', getScheduledPosts, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
