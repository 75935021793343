import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Link } from 'components/Elements/Link';
import { Spinner } from 'components/Elements/Spinner';
import { Logo } from 'components/Layout/Logo';
import { axios } from 'lib/axios';

export function VerifyToken() {
  const { token } = useParams();
  const [verifyStatus, setVerifyStatus] = useState(false);
  async function verifyToken(token: string) {
    const { status } = await axios.post('/user/verify', { token });
    if (status === 200) setVerifyStatus(true);
  }
  useEffect(() => {
    verifyToken(token as string);
  }, [token]);

  if (verifyStatus) {
    return (
      <div className="flex flex-col bg-purple-50 items-center justify-center h-screen w-screen ">
        <Logo />
        <div className="bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-lg transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              Verification Complete
              <div className="mt-2">
                <p className="text-sm text-gray-500">Congratulations verification complete</p>
              </div>
            </div>
          </div>
          <div className="mt-4 flex space-x-2 justify-end">
            <Link
              to="/login"
              className="text-white bg-blue-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2  "
            >
              Go Back To Login
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <Spinner size="xl" />
    </div>
  );
}
