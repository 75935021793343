import { UserResponse } from 'features/auth/types';
import { axios } from 'lib/axios';

export type LoginCredentialsDTO = {
  email: string;
  password: string;
};

export const loginWithEmailAndPassword = (data: LoginCredentialsDTO) => {
  return axios.post<UserResponse>('/user/login', data);
};
