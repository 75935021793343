import { useQuery } from 'react-query';

import { axios } from 'lib/axios';

export async function getProfiles() {
  const { data } = await axios.get('/profile');
  return data;
}

export const useProfiles = () => {
  return useQuery('profile', getProfiles, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });
};
