import { Spinner } from 'components/Elements/Spinner';

export function Fallback() {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <Spinner size="xl" />
      <p className="ml-2 text-lg font-semibold text-neutral-900">Hold up</p>
    </div>
  );
}
