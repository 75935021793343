import { QueryCache, QueryClient, useQuery } from 'react-query';

import { axios } from 'lib/axios';

export const fetchUser = async () => {
  return await axios.get('/user/me');
};

export async function logout() {
  await axios.get('/user/logout', { withCredentials: true });
}

export const useAuth = () => {
  const { data, ...restData } = useQuery('user', fetchUser, {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  if (!data) {
    return { user: null, logout, isAuth: false, ...restData };
  }
  const { status, data: user }: { data: any; status: number } = data;
  const isAuth = status === 200;

  return { user: isAuth ? user : null, logout, isAuth, ...restData };
};

export const clearAllCache = () => {
  const queryClient = new QueryClient();
  const queryCache = new QueryCache();

  queryClient.refetchQueries('user');
  queryClient.clear();
  queryCache.clear();
};
