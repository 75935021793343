import { GOOGLE_CLIENT_ID, GOOGLE_REDIRECT_URI } from 'config';

export async function handleGoogleBusinessAdd() {
  const rootUrl = 'https://accounts.google.com/o/oauth2/auth?';
  const options = {
    client_id: GOOGLE_CLIENT_ID,
    response_type: 'code',
    redirect_uri: GOOGLE_REDIRECT_URI,
    prompt: 'consent',
    access_type: 'offline',
    scope: [
      'https://www.googleapis.com/auth/business.manage',
      'https://www.googleapis.com/auth/plus.business.manage',
      'https://www.googleapis.com/auth/userinfo.profile',
      'https://www.googleapis.com/auth/userinfo.email',
    ].join(' '),
  };
  const params = new URLSearchParams(options);
  const authLink = `${rootUrl}${params.toString()}`;
  window.open(authLink, '_self');
  return '';
}
