import { TWITTER_CLIENT_ID, TWITTER_REDIRECT_URI } from 'config';

// https://developer.twitter.com/en/docs/authentication/oauth-2-0/user-access-token

// Scopes
// https://developer.twitter.com/en/docs/authentication/oauth-2-0/authorization-code
export async function handleTwitterAddOauth2() {
  const rootUrl = 'https://twitter.com/i/oauth2/authorize?';
  const options = {
    response_type: 'code',
    client_id: TWITTER_CLIENT_ID,
    redirect_uri: TWITTER_REDIRECT_URI,
    state: 'socialnexus_twitter',
    code_challenge: 'social',
    code_challenge_method: 'plain',
    scope: [
      'tweet.read',
      'users.read',
      'follows.read',
      'offline.access',
      'tweet.write',
      'tweet.moderate.write',
      'like.read',
      'like.write',
    ].join(' '),
  };
  const params = new URLSearchParams(options);
  const authLink = `${rootUrl}${params.toString()}`;
  window.open(authLink, '_self');
  return '';
}
