import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Button } from 'components/Elements/Button';
import { Modal } from 'components/Elements/Modal';
import { InputField, Form } from 'components/Form';
import { LoginCredentialsDTO, loginWithEmailAndPassword } from 'features/auth/api/login';
import { resendVerificationMail } from 'features/auth/api/verify';
import { useDisclosure } from 'hooks/useDisclosure';

const schema = z.object({
  email: z.string().min(1, 'Required'),
  password: z.string().min(1, 'Required'),
});

type LoginValues = {
  email: string;
  password: string;
};

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const [isloading, setIsLoading] = useState(false);
  const [resendLoading, setResendLoading] = useState(false);
  const { close, open, isOpen } = useDisclosure();
  const [errors, setErrors] = useState({
    email: '',
  });

  async function handleResendEmail() {
    setResendLoading(true);
    await resendVerificationMail(errors.email);
    setResendLoading(false);
    close();
  }

  async function handleSubmit(values: LoginCredentialsDTO) {
    setIsLoading(true);
    setErrors({ email: values.email });
    const { status } = await loginWithEmailAndPassword(values);

    if (status === 200) {
      onSuccess();
    }
    setIsLoading(false);
    if (status === 403) {
      open();
    }
  }

  return (
    <div>
      <Modal
        close={close}
        isOpen={isOpen}
        title="User Verification"
        confirmButton={
          <Button
            type="button"
            isLoading={resendLoading}
            disabled={resendLoading}
            variant="inverse"
            className="w-full inline-flex justify-center rounded-md border focus:ring-1 focus:ring-offset-1 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={handleResendEmail}
          >
            Resend Mail
          </Button>
        }
      >
        <div className="mt-2 ">
          <h3>User not verified</h3>
          <p className="text-sm text-gray-500">Email: {errors.email}</p>
        </div>
      </Modal>
      <Form<LoginValues, typeof schema> onSubmit={handleSubmit} schema={schema}>
        {({ register, formState }) => (
          <>
            <InputField
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label="Password"
              error={formState.errors['password']}
              registration={register('password')}
            />
            <div>
              <Button disabled={isloading} isLoading={isloading} type="submit" className="w-full">
                Log in
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-end">
        <div className="text-sm">
          <Link to="/register" className="font-medium text-blue-600 hover:text-blue-500">
            Register
          </Link>
        </div>
      </div>
    </div>
  );
};
