import { Navigate, Route, Routes } from 'react-router-dom';

import { VerifyToken } from 'features/auth/routes/Verify';

import { Login } from './Login';
import { Register } from './Register';

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="register" element={<Register />} />
      <Route path="login" element={<Login />} />
      <Route path="verify/:token" element={<VerifyToken />} />
      <Route path="*" index element={<Navigate to="login" />} />
    </Routes>
  );
};
