import { create } from 'zustand';

type ContentState = {
  content: string;
  setContent: (value: string) => void;
};

export const usePreviewData = create<ContentState>((set) => ({
  content: '',
  setContent: (value) => set(() => ({ content: value })),
}));

// export const useContent = create((set) => ({
//   bears: 0,
//   content: '',
//   contentRefresh: () =>
//     set((state: any, ...args: any) => {
//       console.log('conet', state, args);
//       return {
//         ...state,
//         content: 'hello',
//       };
//     }),
// }));
