import { LinkedinPreviewCard } from 'features/composer/components/LinkedinPreviewCard';
import { TwitterPreviewCard } from 'features/composer/components/TwitterPreviewCard';
export type PreviewCardProps = {
  space: string;
  post: {
    profile: any;
    createdAt: Date;
    rawPayload: {
      content: string;
    };
  };
};
export function PreviewCard(props: PreviewCardProps) {
  const { space } = props;
  if (space === 'linkedin') return <LinkedinPreviewCard {...props} />;
  if (space === 'twitter') return <TwitterPreviewCard {...props} />;
  return <></>;
}
