import { DateSelectArg, EventClickArg, EventContentArg, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { Fallback } from 'components/Elements/Fallback';
import { ContentLayout } from 'components/Layout';
import { useScheduledPosts } from 'hooks/useScheduledPosts';

function renderEventContent(eventContent: EventContentArg) {
  const { schedule } = eventContent.event.extendedProps ?? {};
  const { profile } = schedule?.post ?? {};
  return (
    <div
      className={clsx(
        'w-full flex gap-2 items-center justify-between rounded p-1',
        schedule.space === 'linkedin' ? 'bg-[#0077b5]' : 'bg-[#1DA1F2]'
      )}
    >
      {/* {profile && profile.profile_pic && (
        <ProfileAvatar
          alt={getFullName(profile.name)}
          space={profile.space}
          src={profile.profile_pic}
        />
      )} */}
      <b className="text-white">{eventContent.timeText}</b>
      <span className="text-white text-">{profile.handle}</span>
    </div>
  );
}

// function SideBar() {
//   return (
//     <div className="demo-app-sidebar">
//       <div className="demo-app-sidebar-section">
//         <h2>Instructions</h2>
//         <ul>
//           <li>Select dates and you will be prompted to create a new event</li>
//           <li>Drag, drop, and resize events</li>
//           <li>Click an event to delete it</li>
//         </ul>
//       </div>
//       <div className="demo-app-sidebar-section">
//         <label>
//           <input
//             type="checkbox"
//             checked={weekendsVisible}
//             // onChange={handleWeekendsToggle}
//           ></input>
//           toggle weekends
//         </label>
//       </div>
//       <div className="demo-app-sidebar-section">
//         <h2>All Events ({currentEvents.length})</h2>
//         <ul>{currentEvents.map(renderSidebarEvent)}</ul>
//       </div>
//     </div>
//   );
// }

let eventGuid = 0;
const todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

export const INITIAL_EVENTS: EventInput[] = [
  {
    id: createEventId(),
    title: 'All-day event',
    start: todayStr,
  },
  {
    id: createEventId(),
    title: 'Timed event',
    start: todayStr + 'T12:00:00',
  },
];

export function createEventId() {
  return String(eventGuid++);
}

export function Scheduler() {
  // const handleEvents = (events: EventApi[]) => console.log;
  const { data, isLoading } = useScheduledPosts();
  const [events, setEvents] = useState<EventInput[]>([]);
  useEffect(() => {
    if (!isLoading && Array.isArray(data)) {
      console.log('event set', data);
      const event = data.map(
        (schedule): EventInput => ({
          id: schedule._id,
          title: schedule.space,
          start: schedule.scheduledAt,
          extendedProps: {
            schedule,
          },
        })
      );
      setEvents(event);
    }
    console.log('events:', events);
  }, [isLoading]);
  // const handleDateSelect = (selectInfo: DateSelectArg) => {
  //   const title = prompt('Please enter a new title for your event');
  //   const calendarApi = selectInfo.view.calendar;

  //   calendarApi.unselect(); // clear date selection

  //   if (title) {
  //     calendarApi.addEvent({
  //       id: createEventId(),
  //       title,
  //       start: selectInfo.startStr,
  //       end: selectInfo.endStr,
  //       allDay: selectInfo.allDay,
  //     });
  //   }
  // };
  // const handleEventClick = (clickInfo: EventClickArg) => {
  //   if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
  //     clickInfo.event.remove();
  //   }
  // };
  return (
    <ContentLayout title="Scheduler">
      <div className="flex justify-center w-full  ">
        {isLoading ? (
          <Fallback />
        ) : (
          <FullCalendar
            height={'auto'}
            // nowIndicatorClassNames={'w-full'}
            // dayCellClassNames={'w-full'}
            // eventClassNames={'bg-red-400'}
            // viewClassNames={'w-full bg-blue-500 border border-1'}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            weekends={true}
            events={events}
            // initialEvents={[{ date: new Date(), backgroundColor: 'red' }]} // alternatively, use the `events` setting to fetch from a feed
            // initialEvents={events} // alternatively, use the `events` setting to fetch from a feed
            // select={handleDateSelect}
            eventContent={renderEventContent} // custom render function
            eventClick={(rest) => console.info('event click:', rest.event.id)}
            eventsSet={(...rest) => console.info('event set:', rest)} // called after events are initialized/added/changed/removed
            /* you can update a remote database when these fire:
            eventAdd={function(){}}
            eventChange={function(){}}
            eventRemove={function(){}}
            */
          />
        )}
      </div>
    </ContentLayout>
  );
}
