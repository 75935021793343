import { Suspense } from 'react';
import { Navigate, Outlet, RouteObject } from 'react-router-dom';

import { Spinner } from 'components/Elements/Spinner';
import { MainLayout } from 'components/Layout';
import { Scheduler } from 'features/composer';
import { Settings } from 'features/settings';
import { lazyImport } from 'utils';

const { Overview } = lazyImport(() => import('features/overview'), 'Overview');
const { Recent } = lazyImport(() => import('features/posts'), 'Recent');
const { CustomerPosts } = lazyImport(() => import('features/posts'), 'CustomerPosts');
const { Profile } = lazyImport(() => import('features/users'), 'Profile');
const { Composer } = lazyImport(() => import('features/composer'), 'Composer');

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <Spinner size="xl" />
          </div>
        }
      >
        <div className="p-4 h-full w-full">
          <Outlet />
        </div>
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes: RouteObject[] = [
  {
    path: '*',
    element: <App />,
    children: [
      // { path: '/discussions/*', element: <DiscussionsRoutes /> },
      { path: 'overview', element: <Overview /> },
      { path: 'posts', element: <Recent /> },
      { path: 'customer', element: <CustomerPosts /> },
      { path: 'composer', element: <Composer /> },
      { path: 'profiles', element: <Profile /> },
      { path: 'settings', element: <Settings /> },
      { path: 'scheduler', element: <Scheduler /> },
      // { path: 'profile', element: <ProfileTemp /> },
      // { path: '/', element: <ProfileTemp /> },
      // { index: true, element: <Overview /> },
      // { path: '/', element: <Dashboard /> },
      { index: true, element: <Navigate to="overview" /> },
      { path: '*', element: <Navigate to="overview" /> },
    ],
  },
];
