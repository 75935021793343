import { Link } from 'react-router-dom';
import { z } from 'zod';

import { Button } from 'components/Elements/Button';
import { InputField, Form } from 'components/Form';
import { RegisterCredentialsDTO, registerWithEmailAndPassword } from 'features/auth/api/register';

const schema = z
  .object({
    email: z.string().min(1, 'Required'),
    firstName: z.string().min(1, 'Required'),
    lastName: z.string().min(1, 'Required'),
    password: z.string().min(8, 'Password must be at least 8 characters'),
    repeatPassword: z.string().min(8, 'Password must be at least 8 characters'),
  })
  .superRefine(({ repeatPassword, password }, ctx) => {
    if (repeatPassword !== password) {
      ctx.addIssue({
        // params: 'ConsoleError',
        path: ['repeatPassword'],
        code: z.ZodIssueCode.custom,
        message: 'The passwords did not match',
      });
    }
  });

type RegisterValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  repeatPassword: string;
};

type RegisterFormProps = {
  onSuccess: () => void;
};

export const RegisterForm = ({ onSuccess }: RegisterFormProps) => {
  return (
    <div>
      <Form<RegisterValues, typeof schema>
        onSubmit={async (values: RegisterCredentialsDTO) => {
          const { status } = await registerWithEmailAndPassword(values);
          if (status === 200) onSuccess();
        }}
        schema={schema}
        options={{
          shouldUnregister: true,
        }}
      >
        {({ register, formState }) => (
          <>
            {/* <ConsoleError formstate={formState} /> */}
            <InputField
              type="text"
              label="First Name"
              error={formState.errors['firstName']}
              registration={register('firstName')}
            />
            <InputField
              type="text"
              label="Last Name"
              error={formState.errors['lastName']}
              registration={register('lastName')}
            />
            <InputField
              type="email"
              label="Email Address"
              error={formState.errors['email']}
              registration={register('email')}
            />
            <InputField
              type="password"
              label="Password"
              error={formState.errors['password']}
              registration={register('password')}
            />
            <InputField
              type="password"
              label="Repeat Password"
              error={formState.errors['repeatPassword']}
              registration={register('repeatPassword')}
            />

            {/* <Switch.Group>
              <div className="flex items-center">
                <Switch
                  checked={chooseTeam}
                  onChange={setChooseTeam}
                  className={`${
                    chooseTeam ? 'bg-blue-600' : 'bg-gray-200'
                  } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                >
                  <span
                    className={`${
                      chooseTeam ? 'translate-x-6' : 'translate-x-1'
                    } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                  />
                </Switch>
                <Switch.Label className="ml-4">Join Existing Team</Switch.Label>
              </div>
            </Switch.Group> */}
            {/* <SelectField
              label="Team"
              error={formState.errors['teamId']}
              registration={register('teamId')}
              options={[{ label: 'asdas', value: 'asdas' }]}
            /> */}

            {/* {chooseTeam && teamsQuery.data ? (
              <SelectField
                label="Team"
                error={formState.errors['teamId']}
                registration={register('teamId')}
                options={teamsQuery?.data?.map((team) => ({
                  label: team.name,
                  value: team.id,
                }))}
              />
            ) : ( */}
            {/* <InputField
              type="text"
              label="Team Name"
              error={formState.errors['teamName']}
              registration={register('teamName')}
            /> */}
            {/* )} */}
            <div>
              <Button isLoading={false} type="submit" className="w-full">
                Register
              </Button>
            </div>
          </>
        )}
      </Form>
      <div className="mt-2 flex items-center justify-end">
        <div className="text-sm">
          <Link to="../login" className="font-medium text-blue-600 hover:text-blue-500">
            Log In
          </Link>
        </div>
      </div>
    </div>
  );
};
