import { Switch } from '@headlessui/react';
import {
  IconArrowAutofitRight,
  IconClock,
  IconPhoto,
  IconSend,
  IconWriting,
} from '@tabler/icons-react';
import { useState } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { toast } from 'react-hot-toast';
import { useQueryClient } from 'react-query';

import { Button } from 'components/Elements/Button';
import { Fallback } from 'components/Elements/Fallback';
import { Modal } from 'components/Elements/Modal';
import { ContentLayout } from 'components/Layout';
import { createPost } from 'features/composer/api/post';
import { ProfileSelect, PreviewCard } from 'features/composer/components';
import { usePreviewData } from 'features/composer/hook/usePreviewData';
import { useDisclosure } from 'hooks/useDisclosure';
import { useProfiles } from 'hooks/useProfile';

export function Composer() {
  const { data: profiles, isFetched, isLoading } = useProfiles();
  const queryClient = useQueryClient();
  const [value, onChange] = useState(new Date());
  const { close, open, isOpen, toggle } = useDisclosure();

  const [selected, setSelected] = useState([] as any[]);
  const [loading, setLoading] = useState(false);

  const { content, setContent } = usePreviewData();

  const resetContent = () => setContent('');

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (!(Array.isArray(selected) && content)) {
        return toast('Content is Empty');
      }
      const payload: any = {
        content,
      };

      if (isOpen) {
        const valueParsedDate = new Date(value).getTime();
        if (valueParsedDate <= new Date().getTime())
          return toast.error('Cant schedule back in time');
        payload['schedule'] = value;
      }
      const { data, status } = await createPost({
        ids: selected.map((profile) => profile._id),
        payload,
      });
      if (status === 200) {
        toast.success('Post Uploaded Successfully');
        resetContent();
        queryClient.refetchQueries('recent_post');
        queryClient.refetchQueries('scheduled_post');
        if (isOpen) queryClient.refetchQueries('schedule_post');
      }
      console.log('data', data, status);
    } catch (error) {
      toast.error('Post Creation Went Wrong');
      console.error('error ', error);
    } finally {
      setLoading(false);
    }
  };

  console.log('date:', value);

  if (isLoading) return <Fallback />;

  return (
    <ContentLayout title="Composer">
      <div className=" ">
        <div className="w-full rounded-lg table-fixed p-4 flex items-center justify-between gap-4  bg-primary-100">
          {/* <Button disabled>Save as template</Button> */}
          <div className="flex ml-auto gap-4 ">
            {/* <Button disabled>Save Draft</Button> */}
            {isOpen ? (
              <Button
                isLoading={loading}
                disabled={loading}
                startIcon={<IconClock className="h-4 w-4" />}
                onClick={handleSubmit}
              >
                Schedule
              </Button>
            ) : (
              <Button
                startIcon={<IconSend className="h-4 w-4" />}
                isLoading={loading}
                disabled={loading}
                onClick={handleSubmit}
              >
                Post now
              </Button>
            )}
          </div>
        </div>
        <div className="flex rounded h-[77vh] mt-4 sm:flex-row flex-col gap-4 ">
          <div className="w-full p-8 overflow-scroll rounded-lg  bg-gray-200  ">
            <div className="">
              <div className="flex items-center py-2 gap-4 ">
                <IconArrowAutofitRight />
                Post To
              </div>
              {isFetched && (
                <ProfileSelect setSelected={setSelected} selected={selected} profiles={profiles} />
              )}
              <div className="flex my-10 flex-col">
                <div className="flex items-center py-2 gap-4 ">
                  <IconWriting />
                  Content
                </div>
                <textarea
                  value={content}
                  onChange={(event) => {
                    setContent(event.target.value);
                  }}
                  name="content"
                  rows={6}
                  placeholder="Post Content here"
                  className="p-2 w-full resize-y rounded-md"
                ></textarea>
              </div>

              <div className="flex flex-col justify-center w-full bg-gray-200">
                <div className="flex items-center py-2 gap-4 ">
                  <IconPhoto />
                  Media
                </div>

                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col items-center justify-center w-full h-48 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 "
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      aria-hidden="true"
                      className="w-10 h-10 mb-3 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 ">
                      {/* <span className="font-semibold">Click to upload</span>  or drag and drop */}
                      <span className="font-semibold">Media Coming Soon</span>
                    </p>
                    {/* <p className="text-xs text-gray-100 ">SVG, PNG, JPG or GIF (MAX. 800x400px)</p> */}
                  </div>
                  <input
                    aria-label="Coming Soon"
                    disabled
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                  />
                </label>
              </div>

              <div className="flex flex-col justify-center w-full ">
                <div className="flex my-10 justify-center flex-col">
                  <div className="flex justify-between items-center py-2 gap-4 ">
                    <div className="flex items-center py-2 gap-4">
                      <IconWriting />
                      Schedule
                    </div>
                    <div className="flex items-center  justify-between">
                      <Switch
                        id="toggle-schedule"
                        checked={isOpen}
                        onChange={toggle}
                        className={`${isOpen ? 'bg-primary-600' : 'bg-primary-300'}
                    relative ml-10 inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
                      >
                        <span className="sr-only">Use setting</span>
                        <span
                          aria-hidden="true"
                          className={`${isOpen ? 'translate-x-9' : 'translate-x-0'}
                      pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                        />
                      </Switch>
                    </div>
                  </div>
                </div>

                {isOpen && (
                  <div>
                    <DateTimePicker onChange={onChange} value={value} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="w-full h-full rounded-lg overflow-y-auto flex flex-col items-center justify-center  bg-gray-400 ">
            {selected.length ? (
              <div className="w-2/3 py-4 h-full">
                {selected.map((profile) => (
                  <PreviewCard
                    space={profile.space}
                    key={profile._id}
                    post={{
                      createdAt: new Date(),
                      profile,
                      rawPayload: {
                        content,
                      },
                    }}
                  />
                ))}
              </div>
            ) : (
              <div className="flex p-10 bg-white rounded-md">Select profiles To Post</div>
            )}
          </div>
        </div>
      </div>
    </ContentLayout>
  );
}
