import { LoginForm } from 'features/auth/components';
import { Layout } from 'features/auth/components/Layout';

export function Login() {
  return (
    <>
      <Layout title="Login Page">
        <LoginForm onSuccess={() => window.location.reload()} />
      </Layout>
    </>
  );
}
