import { UserResponse } from 'features/auth/types';
import { axios } from 'lib/axios';

export type RegisterCredentialsDTO = {
  firstName: string;
  lastName: string;
  email: string;
  repeatPassword: string;
  password: string;
};

export const registerWithEmailAndPassword = (data: RegisterCredentialsDTO) => {
  return axios.post<UserResponse>('/user/register', data);
};
