import * as React from 'react';

import { BreadCrumb } from 'components/Elements/BreadCrumb/BreadCrumb';

import { Head } from '../Head';

type ContentLayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const ContentLayout = ({ children, title }: ContentLayoutProps) => {
  return (
    <>
      <Head title={title} />
      <div className="w-full h-full ">
        <div className="w-full mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">{title}</h1>
          <BreadCrumb />
        </div>
        <div className="w-full  mx-auto px-4 sm:px-6 md:px-8">{children}</div>
      </div>
    </>
  );
};
