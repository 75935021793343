import { axios } from 'lib/axios';
import { sleep } from 'utils';

export async function resendVerificationMail(email: string) {
  console.log('call resend mail', email);
  await sleep(1000);
  const { status } = await axios.post('/user/resendMail', { email });
  if (status === 200) return true;
  return false;
}
