import { Menu, Transition } from '@headlessui/react';
import { IconCircleChevronDown } from '@tabler/icons-react';
import { Fragment } from 'react';

export type DropDownType = {
  options: {
    icon: JSX.Element;
    text: string;
    handler: ({ profile }: { profile: string }) => void;
  }[];
  profile: string;
};

export function DropDown({ options, profile }: DropDownType) {
  return (
    <div className="">
      <Menu as="div" className="  ">
        <div>
          <Menu.Button className="flex w-full items-center justify-center rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 ">
            <IconCircleChevronDown
              className=" h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute z-30 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              {Array.isArray(options) &&
                options.map((option) => (
                  <Menu.Item key={option.text}>
                    {({ active }) => (
                      <button
                        onClick={() => {
                          option.handler({ profile });
                        }}
                        className={`${
                          active ? 'bg-violet-500 text-white' : 'text-gray-900'
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      >
                        {option.icon}
                        {option.text}
                      </button>
                    )}
                  </Menu.Item>
                ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
