import { IconBrandFacebook, IconBrandLinkedin, IconBrandTwitter } from '@tabler/icons-react';

type ProfileAvatarProps = {
  src: string;
  alt: string;
  space: 'linkedin' | 'facebook' | 'twitter';
};

export function ProfileAvatar({ src, alt, space }: ProfileAvatarProps) {
  return (
    <span className="flex relative justify-center  items-center">
      <img className="rounded-full" src={src} alt={alt} width={50} height={50} />
      <div className="absolute right-0 bottom-0">
        {space === 'linkedin' && <IconBrandLinkedin className="w-5 h-5" fill="white" />}
        {space === 'facebook' && <IconBrandFacebook className="w-5 h-5" fill="white" />}
        {space === 'twitter' && (
          <IconBrandTwitter className="w-5 h-5 text-white text-xs fill-blue-400" />
        )}
      </div>
    </span>
  );
}
