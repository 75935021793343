import clsx from 'clsx';
import { Link } from 'react-router-dom';

import logo from 'assets/logo.png';

const sizes = {
  sm: 'h-8 w-auto',
  fill: 'h-full',
};

type LogoProps = {
  image?: string;
  size?: keyof typeof sizes;
  link?: boolean;
  href?: string;
};

export const Logo = ({ image, size = 'sm', link = true, href = '.' }: LogoProps) => {
  if (link)
    return (
      <Link className="flex w-fill items-center gap-2 " to={href}>
        <img className={clsx('', sizes[size])} src={image ?? logo} alt="Workflow" />
        {!image && <span className="text-xl text-black font-sans font-semibold">Social Nexus</span>}
      </Link>
    );
  return (
    <>
      <img className={clsx('', sizes[size])} src={image ?? logo} alt="Workflow" />
      {!image && <span className="text-xl text-black font-sans font-semibold">Social Nexus</span>}
    </>
  );
};
