import { RouteObject, useRoutes } from 'react-router-dom';

import { Spinner } from 'components/Elements/Spinner';
import { Policy } from 'features/misc';
import { useAuth } from 'lib/auth';
import { protectedRoutes } from 'routes/protected';
import { publicRoutes } from 'routes/public';

export const AppRoutes = () => {
  const auth = useAuth();

  const commonRoutes: RouteObject[] = [
    // {
    //   path: '/',
    //   element: <Navigate to="login" />,
    // },
    {
      path: '/policy',
      element: <Policy />,
    },
  ];
  const loadingRoute: RouteObject[] = [
    {
      path: '*',
      element: (
        <div className="flex items-center justify-center w-screen h-screen">
          <Spinner size="xl" />
        </div>
      ),
    },
  ];

  const userRoutes = auth.isAuth ? protectedRoutes : publicRoutes;

  const allRoutes =
    auth.isLoading || auth.isFetching || auth.isRefetching
      ? loadingRoute
      : [...commonRoutes, ...userRoutes];
  // console.log('all routes:', allRoutes, auth.isAuth, auth.isLoading);
  const element = useRoutes(allRoutes);

  return <>{element}</>;
};
