import { IconEye, IconEyeOff } from '@tabler/icons-react';
import clsx from 'clsx';
import { useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password';
  className?: string;
  placeholder?: string;
  registration: Partial<UseFormRegisterReturn>;
};

export const InputField = (props: InputFieldProps) => {
  const { type = 'text', label, placeholder, className, registration, error } = props;
  const [state, setState] = useState(type);
  function toogle() {
    setState(state === 'password' ? 'text' : 'password');
  }
  return (
    <FieldWrapper label={label} error={error}>
      <div className="flex items-center justify-end">
        <input
          type={state}
          placeholder={placeholder}
          className={clsx(
            'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
            className
          )}
          {...registration}
        />
        {type === 'password' && (
          <button type="button" onClick={toogle} className="absolute m-1 w-6 h-6">
            {state === 'password' ? <IconEye /> : <IconEyeOff />}
          </button>
        )}
      </div>
    </FieldWrapper>
  );
};
