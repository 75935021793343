import { ContentLayout } from 'components/Layout';
import { ConnectionCard } from 'features/settings/components';

export function Settings() {
  return (
    <ContentLayout title="Settings">
      <div className="bg-primary-50 p-10 rounded text-lg mb-6 font-bold">
        <h1>Connect Social Accounts</h1>
      </div>
      <ConnectionCard />
    </ContentLayout>
  );
}
