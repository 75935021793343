import { useNavigate } from 'react-router-dom';

import { RegisterForm } from 'features/auth/components';

import { Layout } from '../components/Layout';

export const Register = () => {
  const navigate = useNavigate();

  return (
    <Layout title="Register your account">
      <RegisterForm onSuccess={() => navigate('/login')} />
    </Layout>
  );
};
