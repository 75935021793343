import {
  IconBrandFacebook,
  IconBrandGoogle,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconEdit,
  IconTrash,
} from '@tabler/icons-react';
import { useMemo } from 'react';

import { ProfileAvatar } from 'components/Elements/Avatar';
import { Button } from 'components/Elements/Button';
import { DropDown, DropDownType } from 'components/Elements/DropDown';
import { handleGoogleBusinessAdd } from 'features/settings/api/google';
import { handleLinkedinAdd } from 'features/settings/api/linkedin';
import { handleTwitterAddOauth2 } from 'features/settings/api/twitter';
import { useProfiles } from 'hooks/useProfile';
import { useAuth } from 'lib/auth';
import { getFullName } from 'utils';

type SocialProps = {
  title: string;
  site: 'facebook' | 'googlebusiness' | 'instagram' | 'twitter' | 'linkedin';
  accountType?: string;
  icon: JSX.Element;
  disabled?: boolean;
};

const socials: SocialProps[] = [
  {
    title: 'Linkedin',
    site: 'linkedin',
    accountType: 'Personal Page or LinkedIn Page',
    icon: <IconBrandLinkedin />,
  },
  {
    title: 'Twitter',
    site: 'twitter',
    icon: <IconBrandTwitter />,
  },
  {
    title: 'Facebook',
    site: 'facebook',
    disabled: true,
    accountType: 'Business Accounts',
    icon: <IconBrandFacebook />,
  },
  // {
  //   title: 'Instagram',
  //   site: 'instagram',
  //   disabled: true,
  //   accountType: 'Business or Professional Accounts',
  //   icon: <IconBrandInstagram />,
  // },
  // {
  //   title: 'Google Business Profile',
  //   site: 'googlebusiness',
  //   disabled: true,
  //   icon: <IconBrandGoogle />,
  // },
];

const handleAddSocial = {
  facebook: () => {
    console.log('facebook handler called');
  },
  googlebusiness: handleGoogleBusinessAdd,
  instagram: () => {
    console.log('instagram handler called');
  },
  twitter: handleTwitterAddOauth2,
  linkedin: handleLinkedinAdd,
};

const profileOptions: DropDownType['options'] = [
  {
    icon: <IconEdit className="mr-2 h-5 w-5" aria-hidden="true" />,
    text: 'Edit',
    handler: ({ profile }) => {
      console.log('edit called', profile);
    },
  },
  {
    icon: <IconTrash className="mr-2 h-5 w-5" aria-hidden="true" />,
    text: 'Remove',
    handler: ({ profile }) => {
      console.log('remove called', profile);
    },
  },
];

function SocialCard({ card }: { card: SocialProps }) {
  const { refetch } = useAuth();

  const { data } = useProfiles();

  const activeProfiles = useMemo(() => {
    if (Array.isArray(data)) {
      const profiles: any[] | undefined = [];
      data.forEach((profile) => profile.space === card.site && profiles.push(profile));
      return profiles;
    }
    return undefined;
  }, [card.site, data]);

  return (
    <div className="shadow p-4 h-fit" key={card.site}>
      <div className="flex">
        <div className="flex items-center gap-4">
          {card.icon}
          <span>{card.title}</span>
        </div>
        <Button
          size="sm"
          disabled={card.disabled}
          onClick={async () => {
            const { data } = await refetch();
            if (data?.status === 200) {
              await handleAddSocial[card.site]();
            }
          }}
          className="ml-auto p-0"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-plus"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M12 5l0 14"></path>
            <path d="M5 12l14 0"></path>
          </svg>
        </Button>
      </div>
      <p className="text-sm font-thin pb-2 border-b-2">
        <span className="mx-10 my-6">{card.accountType ?? ''}</span>
      </p>
      {Array.isArray(activeProfiles) &&
        activeProfiles.map((active) => (
          <div
            key={active._id}
            className="p-4 m-1 rounded-md flex bg-red-300 items-center justify-around gap-4"
          >
            {active.profile_pic && (
              <ProfileAvatar
                src={active.profile_pic}
                space={active.space}
                alt={getFullName(active.name)}
              />
            )}
            <p>{getFullName(active.name)}</p>
            <DropDown options={profileOptions} profile={active._id} />
          </div>
        ))}
    </div>
  );
}
export function ConnectionCard() {
  return (
    <div className="grid rounded grid-cols-1 md:grid-cols-2 lg:grid-cols-3  w-full gap-4">
      {socials.map((card, index) => (
        <SocialCard key={card.title + index.toString()} card={card} />
      ))}
    </div>
  );
}
